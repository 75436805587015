import { useDebugStore } from '@/hooks/use-debug-store';
import dynamic from 'next/dynamic';
import React from 'react';
import {
  defaultCurve,
  defaultDelay,
  defaultDuration,
  defaultThreshold,
  defaultYOffset,
} from './config';

export const EnterAnimationContext = React.createContext({
  duration: defaultDuration,
  threshold: defaultThreshold,
  delay: defaultDelay,
  curve: defaultCurve,
  yOffset: defaultYOffset,
});

interface ChildrenProps {
  children: React.ReactNode;
}

const LevaProvider = dynamic<ChildrenProps>(
  () =>
    import('@/components/enter-animaton/with-leva').then(
      (mod) => mod.EnterAnimationLevaProvider
    ),
  { ssr: true }
);

const DefaultProvider = dynamic<ChildrenProps>(
  () =>
    import('@/components/enter-animaton/without-leva').then(
      (mod) => mod.EnterAnimationProvider
    ),
  { ssr: true }
);

export function EnterAnimationProvider({ children }: ChildrenProps) {
  const { isLevaVisible } = useDebugStore();

  const Provider = isLevaVisible ? LevaProvider : DefaultProvider;

  return <Provider>{children}</Provider>;
}
