import { Box } from '@/components/box';
import { ArrowRight } from '@/components/icons/arrow-right';
import { Text } from '@/components/text';
import { CSS, styled } from '@/stitches.config';
import { keys } from '@/utils/keys';
import { SocialIcon } from './social-icon';
import { Description, Title } from './styled-text';
import { AnnouncementBadge } from '@/components/announcement-badge';
import { Badge } from '@/lib/sanity/queries/get-badge';

const socialUrls = {
  instagram: 'https://www.instagram.com/cleverfranke',
  mastodon: 'https://mastodon.online/@cleverfranke',
  linkedin: 'https://www.linkedin.com/company/clever-franke',
  medium: 'https://medium.com/clever-franke',
} as const;

export function EngagementBlock(props: { css?: CSS; badgeData: Badge | null }) {
  return (
    <Wrapper css={props?.css}>
      <Title>News about data, strategy, design and technology</Title>

      <Description>
        Subscribe to our newsletter to receive quarterly updates.
      </Description>

      <Form
        action="https://cleverfranke.createsend.com/t/d/s/njdydu/"
        method="post"
        conversion-on-submit="{ label: 'vW6mCNyo2moQw77m2AM' }"
      >
        <Box css={{ position: 'relative' }}>
          <FormInput
            id="cmEmailAddress"
            name="cm-njdydu-njdydu"
            placeholder="Enter email here"
            required
            type="email"
          />
          <FormSubmit type="submit" aria-label="Sign up">
            <ArrowRight />
          </FormSubmit>
        </Box>
      </Form>

      <SocialAndBadge>
        <Social>
          <Text
            as="h3"
            size={5}
            css={{
              color: '$background',
              marginBottom: '$4',
              '@bp3': {
                marginBottom: '$6',
              },
              '@bp4': {
                whiteSpace: 'nowrap',
              },
              '@bp7': {
                paddingRight: '$4',
              },
            }}
          >
            Or follow us on social
          </Text>

          <Box as="ul" css={{ display: 'flex', gap: '$4' }}>
            {keys(socialUrls).map((channel) => (
              <SocialLink key={`${channel}`}>
                <SocialIcon channel={channel} url={socialUrls[channel]} />
              </SocialLink>
            ))}
          </Box>
        </Social>
        {props.badgeData && props.badgeData.showInFooter && (
          <AnnouncementBadge
            css={{
              flexShrink: 0,
              width: '9rem',
              height: '9rem',
            }}
            invert
            badgeData={props.badgeData}
            animate
            showColors
          />
        )}
      </SocialAndBadge>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  position: 'relative',
  paddingTop: '$6',
  '@bp3': {
    paddingTop: 0,

    '&:before': {
      content: 'none',
    },
  },
});

const FormInput = styled('input', {
  width: '100%',
  backgroundColor: 'transparent',
  border: '1px solid transparent',
  borderBottom: '1px solid $background',
  color: '$background',
  fontSize: '$3',
  padding: '$2 $8 $2 $2',

  '&::placeholder': {
    color: '$gray6',
  },

  '&:focus-visible': {
    outline: 'none',
    border: '1px solid $background',
    backgroundColor: 'transparent',
  },
});

const FormSubmit = styled('button', {
  position: 'absolute',
  top: 0,
  right: '$3',
  bottom: '$2',
  width: '$6',
  backgroundColor: 'transparent',
  height: '100%',

  svg: {
    color: '$background',

    '@motion': {
      transition: 'transform 0.2s',
    },
  },
});

const Form = styled('form', {
  position: 'relative',
  margin: '0 0 $7',
  paddingBottom: '$6',
  maxWidth: '85%',

  '@bp3': {
    paddingBottom: 0,
  },
  '&:hover': {
    [`& ${FormSubmit}`]: {
      svg: {
        transform: 'translateX($space$1)',
      },
    },
  },
});

const SocialAndBadge = styled('div', {
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '$7 $4',

  '@bp2': {
    marginTop: '$11',
  },

  '@bp4': {
    flexFlow: 'row wrap',
    alignItems: 'flex-end',
  },
});

const Social = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const SocialLink = styled('li', {});
