import { EnterAnimation } from '@/components/enter-animaton';
import { GridContainer } from '../grid-container';
import { EngagementBlock } from './components/engagement-block';
import { InquiryBlock } from './components/inquiry-block';
import { Badge } from '@/lib/sanity/queries/get-badge';

export function Contact(props: { badgeData: Badge | null }) {
  return (
    <EnterAnimation>
      <GridContainer
        css={{
          padding: '$8 0 $6',
          '@bp4': {
            padding: '$10 0 $11',
          },
        }}
      >
        <InquiryBlock
          css={{
            gridColumn: 'main',
            '@bp4': {
              gridRow: 1,
              gridColumn: 'main / span 7',
            },
          }}
        />
        <EngagementBlock
          css={{
            gridColumn: 'main',
            '@bp4': {
              gridRow: 1,
              gridColumn: '10 / span 7',
            },
          }}
          badgeData={props.badgeData}
        />
      </GridContainer>
    </EnterAnimation>
  );
}
