import { Logo } from '@/components/logo';
import { Text } from '@/components/text';
import { ThemeSwitch } from '@/components/theme-switch';
import { styled } from '@/stitches.config';
import Link from 'next/link';

export function Footer() {
  return (
    <FooterBox
      as="footer"
      css={{
        gap: '$5',
      }}
    >
      <LogoBox>
        <Link href="/">
          <a aria-label="Visit the Clever°Franke homepage">
            <FooterLogo />
          </a>
        </Link>
        <Text
          as="p"
          size={1}
          css={{
            mb: 0,
            marginTop: '$1',
            color: '$background',

            '@bp5': {
              marginTop: 4,
              paddingLeft: '$1',
            },
          }}
        >
          Data driven experiences
        </Text>
      </LogoBox>
      <ThemeBox>
        <ThemeSwitch />
      </ThemeBox>
      <Terms>
        <Text size={1} css={{ mb: 0, '@bp5': { mt: 4 } }}>
          © {`${new Date().getFullYear()} `}
        </Text>
        <Link href="/privacy-policy" passHref>
          <Anchor
            aria-label="View our privacy policy"
            css={{ '@bp5': { mt: 4 } }}
          >
            <Text size={1} css={{ mb: 0 }}>
              Privacy policy
            </Text>
          </Anchor>
        </Link>
      </Terms>
    </FooterBox>
  );
}

const FooterBox = styled('div', {
  display: 'grid',
  gridTemplateAreas: `
  'logo theme'
  'copyright .'
  `,
  gridTemplateColumns: '2.5fr 1fr',
  padding: '$4 $4 $5',
  backgroundColor: 'none',

  '@bp3': {
    padding: '$5 $6',
  },

  '@bp4': {
    padding: '$5 $8',
  },

  '@bp5': {
    display: 'flex',
    alignItems: 'center',
  },
});

const LogoBox = styled('div', {
  textAlign: 'left',
  marginTop: '$6',

  '@bp5': {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'row',
    marginInlineEnd: 'auto',
    alignItems: 'center',
    flex: '1',
  },
});

const FooterLogo = styled(Logo, {
  color: '$background',
  height: '$4',
  width: '$12',
  '@bp5': {
    marginRight: '$4',
  },
});

const Terms = styled('p', {
  color: '$gray9',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'left',
  gap: '$4',
  mb: 0,

  '@bp5': {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: '1',
    marginRight: 'auto',
    textAlign: 'center',
  },
});

const ThemeBox = styled('div', {
  alignSelf: 'start',
  justifySelf: 'end',
  marginTop: '$5',
  '@bp5': {
    marginTop: '0.1rem',
  },
});

const Anchor = styled('a', {
  border: 0,
  color: 'inherit',
  cursor: 'pointer',
  textDecoration: 'none',
  transition: '0.35s color',
  display: 'block',

  '&:hover': {
    opacity: 0.7,
  },
});
